import { reactive, toRefs, getCurrentInstance, watch, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { niceForm } from '@/common/form/NiceForm'
import { registerForm as initRegisterForm } from '@/common/form/RegisterForm'
import { fnModal } from '@/components/common/modal'
import { openSnsPop } from '@/common/GlobalFunction'

export const componentState = () => {
  const prof = ref(null) // ref
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const router = useRouter()
  const registerForm = getters['user/getRegisterForm']
  if (
    proxy.$Util.isEmpty(registerForm.useAgree) ||
    registerForm.useAgree !== proxy.$ConstCode.BOOLEAN_VALUE.TRUE ||
    proxy.$Util.isEmpty(registerForm.priAgree) ||
    registerForm.priAgree !== proxy.$ConstCode.BOOLEAN_VALUE.TRUE
  ) {
    router.go(-1)
  }
  const state = reactive({
    bankList: [], // 은행코드 리스트
    registerForm: { ...registerForm },
    confirmPw: '',
    inputFile: '',
    file: '',
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/regist/success`,
    failRtnUrl: `${window.location.origin}/regist/fail`,
    kakaoPageUrl: '',
    naverPageUrl: '',
    googlePageUrl: ''
  })
  const fnChange = event => {
    // if (typeof event.target.files[0] === 'object') {
    //   state.file = event.target.files[0]
    // } else {
    //   state.file = ''
    // }
    if (typeof event.target.files[0] === 'object') {
      const maxSize = 3 * 1024 * 1024
      const fileSize = event.target.files[0].size
      if (fileSize > maxSize) {
        alert('첨부파일은 3MB 미만의 용량으로 등록해주세요.')
        // state.file = ''
      } else {
        state.inputFile = event.target.files[0]
      }
    } else {
      state.file = ''
      prof.value.src = ''
    }
  }
  const fnBankList = async () => {
    try {
      const res = await proxy.$CommonSvc.postBankList()
      state.bankList = res.list
      const head = proxy.$_.head(state.bankList)
      if (!proxy.$Util.isEmpty(head)) {
        state.registerForm.bankCode = head.bankCode
      }
    } catch (err) {
      console.error(err)
    }
  }
  const fnPhoneAuth = async () => {
    try {
      await dispatch('user/updateRegisterForm', {
        registerForm: { ...state.registerForm }
      })
      const params = {}
      params.callType = proxy.$ConstCode.NICE_CALL_TYPE.INIT_REGIST.value
      params.rtnUrl = state.rtnUrl
      params.failRtnUrl = state.failRtnUrl
      params.loginType = state.registerForm.loginType
      const res = await proxy.$UserSvc.postAuthNiceAuthPop(params)
      if (proxy.$Util.isEmpty(res.niceData)) {
        alert(res.niceMessage)
        return
      }
      state.niceForm.encodeData = res.niceData
    } catch (err) {
      console.error(err)
    }
  }

  const fnCallback = async (isBind = true) => {
    if (isBind) {
      state.registerForm = { ...getters['user/getRegisterForm'] }
    } else {
      setTimeout(() => {
        alert('이미 가입된 회원입니다.')
      }, 500)
    }
  }

  const fnCallback2 = async params => {
    if (params.resultCode === '0000') {
      // 로그인한 회원데이터
      await dispatch('user/signIn', {
        authorization: params.authorization,
        data: params
      })
      await router.replace({ path: '/main', query: { isRoot: 1 } })
    } else if (params.resultCode === '0006') {
      const temp = getters['user/getRegisterForm']
      const registerForm = { ...initRegisterForm }
      registerForm.useAgree = temp.useAgree
      registerForm.priAgree = temp.priAgree
      registerForm.smsAgree = temp.smsAgree
      registerForm.emailAgree = temp.emailAgree
      registerForm.loginType = Number(params.loginType)
      // registerForm.nick = params.nick
      registerForm.id = params.snsId
      await dispatch('user/updateRegisterForm', { registerForm })
      await router.replace({ path: '/regist/terms' })
      await router.push({ path: '/regist/form' })
    } else {
      if (!proxy.$Util.isEmpty(params.resultCode)) {
        alert(params.resultMsg)
      } else {
        alert('인증에 실패하였습니다.')
      }
    }
  }

  const fnUserIdDupl = async () => {
    // if (!proxy.$Util.isValidNick(state.registerForm.nick)) {
    //   alert('닉네임을 한글 2~8자, 영문/숫자 3~16자 이내로 입력해주세요.')
    //   return false
    // }
    try {
      const params = {}
      params.userId = state.registerForm.userId
      const res = await proxy.$UserSvc.postCheckDuplById(params)
      if (res.resultCode === '0000') {
        state.registerForm.isIdDupl = proxy.$ConstCode.BOOLEAN_VALUE.TRUE
        return true
      } else {
        state.registerForm.isIdDupl = proxy.$ConstCode.BOOLEAN_VALUE.FALSE
        alert(res.resultMsg)
        return false
      }
    } catch (e) {
      console.error(e)
    }
  }
  const fnNickDupl = async () => {
    try {
      const params = {}
      params.nick = state.registerForm.nick
      const res = await proxy.$UserSvc.postCheckDuplByNick(params)
      alert(res.resultMsg)
      if (res.resultCode === '0000') {
        state.registerForm.isNickDupl = proxy.$ConstCode.BOOLEAN_VALUE.TRUE
      } else {
        state.registerForm.isNickDupl = proxy.$ConstCode.BOOLEAN_VALUE.FALSE
      }
      await dispatch('user/updateRegisterForm', {
        registerForm: state.registerForm
      })
    } catch (e) {
      console.error(e)
    }
  }
  const fnDone = async (type = 0) => {
    if (type === 1) {
      // 저장 or 완료
      if (!isValidForDone()) return
    }
    if (proxy.$Util.isEmpty(state.registerForm.loginType)) {
      if (!(await fnUserIdDupl())) {
        return
      }
    }
    try {
      let res = ''
      if (proxy.$Util.isEmpty(state.registerForm.loginType)) {
        const request = {
          userId: state.registerForm.userId,
          pw: state.registerForm.pw,
          niceSeq: state.registerForm.niceSeq,
          niceId: state.registerForm.niceId,
          nick: state.registerForm.nick,
          accountNum: state.registerForm.accountNum,
          bankCode: state.registerForm.bankCode,
          useAgree: state.registerForm.useAgree,
          priAgree: state.registerForm.priAgree,
          smsAgree: state.registerForm.smsAgree,
          emailAgree: state.registerForm.emailAgree,
          recomendrCode: state.registerForm.recomendrCode
        }
        const params = {
          request: JSON.stringify(request),
          file: state.file
        }
        res = await proxy.$UserSvc.postAdd(params)
        if (res.resultCode === '0000') {
          await dispatch('user/clearRegisterForm')
          await router.replace({
            name: 'regist-mail-index',
            params: { userId: res.userId, deleteDt: res.deleteDt }
          })
        } else {
          alert(res.resultMsg)
        }
      } else {
        const request = {
          loginType: state.registerForm.loginType,
          id: state.registerForm.id,
          pw: state.registerForm.pw,
          niceId: state.registerForm.niceId,
          niceSeq: state.registerForm.niceSeq,
          nick: state.registerForm.nick,
          accountNum: state.registerForm.accountNum,
          bankCode: state.registerForm.bankCode,
          useAgree: state.registerForm.useAgree,
          priAgree: state.registerForm.priAgree,
          smsAgree: state.registerForm.smsAgree,
          emailAgree: state.registerForm.emailAgree,
          recomendrCode: state.registerForm.recomendrCode
        }
        console.log('request {}', request)
        const params = {
          request: JSON.stringify(request),
          file: state.file
        }
        res = await proxy.$UserSvc.postSnsSignUp(params)
        if (res.resultCode === '0000') {
          await dispatch('user/clearRegisterForm')
          await router.replace({ path: '/regist/done' })
          //TODO:회원가입 완료 페이지에서 추천인등록 페이지 추가
          // await router.replace({ path: '/regist/recommend' })
        } else {
          alert(res.resultMsg)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  const isValidForDone = () => {
    if (
      state.registerForm.isPhoneAuth !== proxy.$ConstCode.BOOLEAN_VALUE.TRUE
    ) {
      alert('휴대폰 본인인증이 필요합니다.')
      return false
    }
    // if (!proxy.$Util.isValidNick(state.registerForm.nick)) {
    //   alert('닉네임을 한글 2~8자, 영문/숫자 3~16자 이내로 입력해주세요.')
    //   return false
    // }
    if (state.registerForm.isNickDupl !== proxy.$ConstCode.BOOLEAN_VALUE.TRUE) {
      alert('닉네임 중복체크를 해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.registerForm.loginType)) {
      if (proxy.$Util.isEmpty(state.registerForm.userId)) {
        alert('이메일 아이디를 입력해주세요.')
        return false
      }
    }
    if (!proxy.$Util.isValidPassword(state.registerForm.pw)) {
      alert(
        '영문, 숫자, 특수문자를 모두 포함하여 8자~12자 이내로 입력해주세요.'
      )
      return false
    }
    if (state.registerForm.pw !== state.confirmPw) {
      alert('비밀번호가 일치하지 않습니다. 비밀번호를 다시 입력해주세요.')
      return false
    }
    return true
  }
  const fnModalCropper = async (src, fileName) => {
    const payload = {
      component: proxy.$modalNames.CROPPER,
      data: {
        img: src,
        fileName: fileName
      },
      callback: (src, file) => {
        prof.value.src = src
        state.file = file
      }
    }
    await fnModal(payload)
  }
  const fnSnsLogin = async (loginType = 0) => {
    let authorizePageUri = ''
    if (loginType === proxy.$ConstCode.SNS_LOGIN_TYPE.KAKAO.value) {
      authorizePageUri = state.kakaoPageUrl
    } else if (loginType === proxy.$ConstCode.SNS_LOGIN_TYPE.NAVER.value) {
      authorizePageUri = state.naverPageUrl
    } else {
      authorizePageUri = state.googlePageUrl
    }
    const newWindow = window.open(authorizePageUri)
    openSnsPop(newWindow, fnCallback2)
  }
  const fnGetSnsPageUrl = async (loginType = 1) => {
    const params = {}
    params.login_type = loginType
    params.rtnUrl = `${window.location.origin}/oauth`
    const res = await proxy.$UserSvc.postOauthLogin(params)
    if (loginType === proxy.$ConstCode.SNS_LOGIN_TYPE.KAKAO.value) {
      state.kakaoPageUrl = res.authorizePageUri
    } else if (loginType === proxy.$ConstCode.SNS_LOGIN_TYPE.NAVER.value) {
      state.naverPageUrl = res.authorizePageUri
    } else if (loginType === proxy.$ConstCode.SNS_LOGIN_TYPE.GOOGLE.value) {
      state.googlePageUrl = res.authorizePageUri
    }
  }
  const fnDelete = () => {
    state.inputFile = ''
    state.file = ''
  }
  /** watch **/
  watch(
    () => state.inputFile,
    val => {
      if (typeof val === 'object') {
        const reader = new FileReader()
        reader.onload = async event => {
          await fnModalCropper(event.target.result, val.name)
          // prof.value.src = event.target.result
        }
        reader.readAsDataURL(val)
      } else {
        prof.value.src = ''
      }
    }
  )
  watch(
    () => state.registerForm.nick,
    () => {
      // 변경시 마다 중복체크 다시 필요
      state.registerForm.isNickDupl = proxy.$ConstCode.BOOLEAN_VALUE.FALSE
    }
  )
  watch(
    () => state.registerForm.accountNum,
    val => {
      if (!proxy.$Util.isEmpty(val) && val.length > 40) {
        state.registerForm.accountNum = val.substr(0, 40)
      }
    }
  )
  watch(
    () => state.registerForm.birth,
    async val => {
      // 19세 미만 체크
      if (!proxy.$Util.isEmpty(val)) {
        const age = proxy.$Util.getBirthAge(proxy.$dayjs(val).toDate())
        console.log(age)
        if (age < 19) {
          await router.replace({ path: '/regist/noti' })
        }
      }
    }
  )
  watch(
    () => state.registerForm,
    async val => {
      await dispatch('user/updateRegisterForm', {
        registerForm: val
      })
    },
    { deep: true }
  )
  /** init **/
  const init = async () => {
    if (
      !proxy.$Util.isEmpty(state.registerForm.loginType) &&
      Number(state.registerForm.loginType) ===
        proxy.$ConstCode.SNS_LOGIN_TYPE.NAVER.value
    ) {
      await fnDone(0)
    }
    await fnBankList()
    if (proxy.$Util.isEmpty(state.registerForm.loginType)) {
      await fnGetSnsPageUrl(proxy.$ConstCode.SNS_LOGIN_TYPE.KAKAO.value)
      await fnGetSnsPageUrl(proxy.$ConstCode.SNS_LOGIN_TYPE.NAVER.value)
      await fnGetSnsPageUrl(proxy.$ConstCode.SNS_LOGIN_TYPE.GOOGLE.value)
    }
  }
  init()
  return {
    prof,
    ...toRefs(state),
    fnChange,
    fnPhoneAuth,
    fnCallback,
    fnUserIdDupl,
    fnNickDupl,
    fnDone,
    fnSnsLogin,
    fnDelete
  }
}
