<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <h1 class="con_ttl center">
            <span>회원 가입</span>
          </h1>
          <div class="form_wrap">
            <div class="form">
              <ul class="form_ul">
                <li
                  class="form_li"
                  v-if="$Util.isEmpty(registerForm.loginType)"
                >
                  <div class="form_ttl essen">아이디(이메일)</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="email"
                        placeholder="이메일 주소를 입력해 주세요."
                        v-model="registerForm.userId"
                      />
                    </label>
                    <div class="hint">가입 완료 후 인증메일이 발송됩니다.</div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">휴대폰 인증</div>
                  <div
                    class="form_in"
                    v-if="
                      registerForm.isPhoneAuth !== $ConstCode.BOOLEAN_VALUE.TRUE
                    "
                  >
                    <button class="btn" @click="fnPhoneAuth">인증하기</button>
                    <!--                    <div class="hint">-->
                    <!--                      휴대폰 본인인증을 진행합니다. 성인만 가입할 수 있습니다.-->
                    <!--                    </div>-->
                  </div>
                  <div class="form_in" v-else>
                    <div class="bind">
                      <span class="color"
                        >{{
                          `${$Util.getPhoneFormat(registerForm.phone, 1)} ${
                            registerForm.name
                          }`
                        }}
                        인증되었습니다.</span
                      >
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">닉네임</div>
                  <div class="form_in">
                    <div class="inline">
                      <label class="input">
                        <input
                          type="text"
                          placeholder="닉네임을 입력해 주세요."
                          v-model="registerForm.nick"
                        />
                      </label>
                      <button class="btn" @click="fnNickDupl">중복체크</button>
                    </div>
                    <div class="hint">
                      한글 2~8자, 영문/숫자 3~16자 이내로 입력해 주세요.
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">비밀번호</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="password"
                        placeholder="비밀번호를 입력해 주세요."
                        v-model="registerForm.pw"
                      />
                    </label>
                    <div class="hint">
                      영문, 숫자, 특수문자를 모두 포함하여 8자~12자 이내로
                      입력해 주세요.
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">비밀번호 확인</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="password"
                        placeholder="비밀번호를 한번 더 입력해 주세요."
                        v-model="confirmPw"
                      />
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">회원 섬네일</div>
                  <div class="form_in">
                    <label class="input_file">
                      <input
                        type="file"
                        accept="image/gif, image/jpeg, image/png"
                        @change="fnChange"
                        onclick="this.value = ''"
                      />
                      <span class="label bo">파일 선택</span>
                    </label>
                    <div
                      class="input_file_name"
                      v-show="typeof file === 'object'"
                    >
                      <div class="prof">
                        <div class="img_area">
                          <img :src="''" alt="" ref="prof" />
                        </div>
                      </div>
                      <span>{{ file.name }}</span>
                      <button class="btn_delete" @click="fnDelete">삭제</button>
                    </div>
                    <div class="hint">
                      3MB 미만의 PNG, JPG or GIF 파일만 가능합니다.
                    </div>
                  </div>
                </li>
                <!--                <li class="form_li">
                  <div class="form_ttl">계좌 등록</div>
                  <div class="form_in">
                    <div class="inline">
                      <label class="select">
                        <select v-model="registerForm.bankCode">
                          <option
                            v-for="item in bankList"
                            :key="item.bankCode"
                            :value="item.bankCode"
                            >{{ item.bankNm }}</option
                          >
                        </select>
                      </label>
                      <label class="input">
                        <input
                          type="number"
                          inputmode="decimal"
                          pattern="\d*"
                          placeholder="숫자만 입력(40자 이내)"
                          v-model="registerForm.accountNum"
                        />
                      </label>
                    </div>
                    <label class="input">
                      <input
                        type="text"
                        placeholder="예금주 명을 입력해 주세요."
                        v-model="registerForm.name"
                        disabled
                      />
                    </label>
                    <div class="hint">
                      * 파티장(판매자) 캐시를 현금 변환 시 계좌 정보가
                      필요합니다.
                    </div>
                    <div class="hint">
                      * 반드시 인증을 받은 본인 명의의 계좌로만 등록해 주세요.
                    </div>
                  </div>
                </li>-->
              </ul>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap" v-if="$Util.isEmpty(registerForm.loginType)">
              <button class="btn" @click="fnDone(1)">다음</button>
            </div>
            <div class="btn_wrap" v-else>
              <button class="btn bo" @click="fnDone(0)">다음에 설정</button>
              <button class="btn" @click="fnDone(1)">저장</button>
            </div>
          </div>
          <div
            class="form_wrap"
            v-if="$Util.isEmpty(registerForm.loginType)"
            style="padding-top: 0"
          >
            <div class="form login">
              <!--'로그인: login'-->
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_in">
                    <div class="openid">
                      <div class="openid_ttl">openID로 로그인</div>
                      <div class="openid_bts">
                        <button
                          class="kakao"
                          @click="
                            fnSnsLogin($ConstCode.SNS_LOGIN_TYPE.KAKAO.value)
                          "
                        >
                          <span>카카오 계정으로 로그인</span>
                        </button>
                        <button
                          class="naver"
                          @click="
                            fnSnsLogin($ConstCode.SNS_LOGIN_TYPE.NAVER.value)
                          "
                        >
                          <span>네이버 계정으로 로그인</span>
                        </button>
                        <button
                          class="google"
                          @click="
                            fnSnsLogin($ConstCode.SNS_LOGIN_TYPE.GOOGLE.value)
                          "
                        >
                          <span>구글 계정으로 로그인</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nice
      :m="niceForm.m"
      :encode-data="niceForm.encodeData"
      :call-type="niceForm.callType"
      :rtn-url="rtnUrl"
      :fail-rtn-url="failRtnUrl"
      :callback="fnCallback"
    />
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'regist-form-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
